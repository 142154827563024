.actionButtons {
  display: flex;
  flex-direction: row-reverse;
  margin-top: 3rem;
}

.actionButtonsWrapper {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
  gap: 0.75rem;
}

.resultsHeaderWrapper {
  display: flex;
  margin-top: 1.5rem;
}

.resultsHeader {
  text-align: center;
  flex-grow: 1;
  width: 33%;
}

.resultsHeaderWrapper > .resultsHeader:first-child {
  text-align: left;
  font-weight: 500;
}

.footerMessage {
  display: block;
  margin-top: 3rem;
  font-weight: 500;
}

.totalValue {
  font-weight: 500;
  color: #222222;
  text-transform: capitalize;
}

.totalEmptyLabel {
  color: #777777;
}

.snapshotsLabel {
  display: inline-block;
  font-weight: 500;
  margin-right: 0.5rem;
}

.justificationLabel {
  display: inline-block;
  margin-right: 0.5rem;
}

.justificationValue {
  font-weight: 500;
}

.contentWrapper {
  margin-top: 3rem;
}

.snapshotsWrapper {
  display: flex;
  max-width: 70%;
  flex-wrap: wrap;
  margin: 1rem 0 0.5rem 0;
}

.justificationWrapper {
  display: flex;
  max-width: 70%;
  flex-wrap: wrap;
  margin: 3rem 0 0.5rem 0;
}

.snapshotsWrapper > div {
  margin: 0 0.5rem 0.5rem 0;
}

.notEligible {
  font-weight: 500;
  color: #dd0000;
  border-radius: 8px;
  background-color: #dd000014;
  padding: 0.65rem;
  font-size: 0.95rem;
}

.warningMessageWrapper {
  flex-grow: 1;
  margin-top: 1rem;
}

.actionButton {
  display: flex;
  justify-content: flex-end;
}

.actionsWaring {
  display: block;
  margin-top: 0.5rem;
}

.dateText {
  display: block;
  font-size: 0.9rem;
  color: #555555;
}

.unlockWarningMessageWrapper {
  display: flex;
  justify-content: flex-end;
  margin-top: 3rem;
}

.justificationsToProceedTitle {
  display: block;
  margin: 3rem 0 1rem 0;
  font-weight: 500;
}

.snapshotInfoWrapper {
  display: inline-flex;
}
