.linksWrapper {
  margin-top: 1.5rem;
}

.linkItemWrapper {
  margin: 0.5rem 0;
  display: flex;
  align-items: center;
}

.linkItemWrapper > a {
  color: #777777;
  font-size: 0.85rem;
}

.linkItemWrapper > *:last-child {
  margin-left: 0.5rem;
}
